import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, TextField, CircularProgress } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const CustomToolbar = ({ onDateChange, startDate, handleStartDateChange, endDate, handleEndDateChange, handleDateRangeChange }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ allColumns: true }} sx={{ '.MuiButtonBase-root': { color: 'white' } }} />
    </GridToolbarContainer>
  );
};

const DeviceLogs = observer(({ events, onDateChange, loading }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleDateRangeChange = useCallback(() => {
    if (startDate && endDate) {
      onDateChange({ start: startDate, end: endDate });
    }
  }, [startDate, endDate, onDateChange]);

  const columns = [
    { field: 'dateTime', headerName: 'Date & Time', width: 200, valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') },
    { field: 'equipmentName', headerName: 'Equipment', width: 150 },
    { field: 'deviceName', headerName: 'Device', width: 150 },
    { field: 'gatewayName', headerName: 'Gateway', width: 150 },
    { field: 'raw', headerName: 'Raw Data', width: 150 },
    { field: 'data', headerName: 'Data', width: 150 },
  ];


  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Device Event Logs</Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Start Date"
          type="datetime-local"
          value={startDate}
          onChange={handleStartDateChange}
          onBlur={handleDateRangeChange}
          InputLabelProps={{ shrink: true }}
          sx={{ width: '200px' }}
        />
        <TextField
          label="End Date"
          type="datetime-local"
          value={endDate}
          onChange={handleEndDateChange}
          onBlur={handleDateRangeChange}
          InputLabelProps={{ shrink: true }}
          sx={{ width: '200px' }}
        />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        !events || events.length === 0 ? (
          <Typography variant="subtitle1">No events available</Typography>
        ) : (
          <DataGrid
            rows={events}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row._id}
            components={{
              Toolbar: () => <CustomToolbar onDateChange={onDateChange} startDate={startDate} handleStartDateChange={handleStartDateChange} endDate={endDate} handleEndDateChange={handleEndDateChange} handleDateRangeChange={handleDateRangeChange} />,
            }}
          />
        )
      )}
    </Box>
  );
});

export default DeviceLogs;
