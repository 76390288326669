import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {
  const faqs = [
    { question: "How do I set up a new gateway?", answer: "To set up a new gateway, you need to provide a unique name and serial number for the gateway. Navigate to the 'Add Gateway' section in your dashboard and fill in the required fields." },
    { question: "What are the requirements for adding a new device?", answer: "To add a new device, it’s required to have a unique name and serial number. Go to the 'Add Device' section, select the gateway you want to connect it to, and provide the necessary details." },
    { question: "How do I reset my password?", answer: "To reset your password, go to the settings page and follow the password reset instructions." },
    { question: "How can I contact customer support?", answer: "You can contact customer support via email at support@kratesoft.com or by calling our helpline." },
    { question: "How does device connectivity work?", answer: "Devices can only be seen when they are connected. Real-time information is only available for connected devices." },
    { question: "Will my device history contain gaps?", answer: "Yes, if a device is not connected, the history will contain gaps for the periods when the device was disconnected." },
    { question: "How does the map display devices?", answer: "The map will show all devices that have at some point connected. Devices will be green when active." },
    // Add more FAQs here
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Help & Support</Typography>

      <Typography variant="h5" sx={{ mb: 2 }}>Frequently Asked Questions</Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 4 }}
        onClick={() => window.location.href = '/contact-support'} // Replace with your actual support link
      >
        Get Support
      </Button>
    </Box>
  );
};

export default Help;
