import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography, Button, TextField, MenuItem, InputAdornment, List, ListItem, ListItemText, Grid } from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import ListViewIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import deviceStore from '../stores/DeviceStore';

const DeviceTable = observer(() => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('list');
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [filteredDevices, setFilteredDevices] = useState(deviceStore.devices);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    deviceStore.loadDevices();
    setStatusOptions(['', ...new Set(deviceStore.devices.map(device => device.status))]);
  }, []);

  useEffect(() => {
    filterDevices();
  }, [searchText, statusFilter, deviceStore.devices]);

  const columns = [
    { field: 'name', headerName: 'Device Name', width: 150 },
    { field: 'status', headerName: 'Status', width: 110 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 150 },
    { field: 'equipment', headerName: 'Equipment', width: 150 },
    { field: 'gateway', headerName: 'Gateway', width: 150 },
    { field: 'action', headerName: 'Action', width: 150, sortable: false, renderCell: (params) => (<Button onClick={() => navigate(`/device/${params.id}`)}>Details</Button>) },
  ];

  const filterDevices = () => {
    let filtered = deviceStore.devices;
    if (searchText) {
      const lowerCaseSearchText = searchText.toLowerCase();
      filtered = filtered.filter(device =>
        device.name.toLowerCase().includes(lowerCaseSearchText) ||
        device.serialNumber.toLowerCase().includes(lowerCaseSearchText)
      );
    }
    if (statusFilter) {
      filtered = filtered.filter(device => device.status === statusFilter);
    }
    setFilteredDevices(filtered);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Devices</Typography>
        <Box>
          <IconButton onClick={() => setViewMode('list')}>
            <ListViewIcon color={viewMode === 'list' ? 'primary' : 'disabled'} />
          </IconButton>
          <IconButton onClick={() => setViewMode('table')}>
            <TableViewIcon color={viewMode === 'table' ? 'primary' : 'disabled'} />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={filterDevices}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Status Filter"
            select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            variant="outlined"
          >
            {statusOptions.map(status => (
              <MenuItem key={status} value={status}>
                {status || 'All'}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {viewMode === 'table' ? (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredDevices.map(device => ({ id: device._id, ...device }))}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
          />
        </div>
      ) : (
        <List sx={{ maxHeight: 400, overflow: 'auto' }}>
          {filteredDevices.map((device) => (
            <ListItem key={device._id} button onClick={() => navigate(`/device/${device._id}`)}>
              <ListItemText
                primary={device.name}
                secondary={`Serial Number: ${device.serialNumber}, Status: ${device.status}`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
});

export default DeviceTable;
