import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography, Snackbar, Alert, Paper, LinearProgress, Button } from '@mui/material';
import DeviceInfo from '../components/DeviceInfo';
import DeviceLogs from '../components/DeviceLogs';
import deviceDetailStore from '../stores/DeviceDetailStore';
import eventStore from '../stores/EventStore';

const DownloadOverlay = ({ show, message }) => {
  if (!show) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 10000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white'
    }}>
      <Typography variant="h4">{message}</Typography>
    </div>
  );
};

const DeviceDetails = observer(() => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  useEffect(() => {
    const fetchData = async () => {
      if (isDownloadingCSV) return;

      setIsLoading(true);
      try {
        await deviceDetailStore.loadDeviceInfo(id);
        const filter = dateRange.start && dateRange.end ? 
          JSON.stringify({ 
            device: id, 
            dateTime: { 
              $gte: new Date(dateRange.start).toISOString(), 
              $lte: new Date(dateRange.end).toISOString()
            }
          }) : 
          `{"device":"${id}"}`;
        await eventStore.loadEvents({ filter, limit: 1000, page: 1, sortBy: '-dateTime' });
        setSnackbarMessage('Device details loaded successfully');
        setSeverity('success');
      } catch (error) {
        setSnackbarMessage(error.message || 'Failed to load device details');
        setSeverity('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 360000);

    return () => clearInterval(intervalId);
  }, [id, dateRange, isDownloadingCSV]);

  const handleExportAllEvents = async () => {
    setIsDownloadingCSV(true);
    try {
      const filter = JSON.stringify({ device: id });
      await eventStore.downloadEventsCSV({ filter });
      setSnackbarMessage('Export started');
      setSeverity('info');
    } catch (error) {
      setSnackbarMessage('Error during export');
      setSeverity('error');
    } finally {
      setIsDownloadingCSV(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Paper sx={{ m: 3, p: 3 }}>
        <DeviceInfo />
        <Typography variant="h6" sx={{ mt: 2 }}>Event Log for {deviceDetailStore.deviceInfo?.name}</Typography>
        <Button variant="contained" color="primary" onClick={handleExportAllEvents} disabled={isDownloadingCSV} sx={{ mb: 2, color: 'white' }}>
          Export All Events
        </Button>
        <DeviceLogs events={eventStore.events} onDateChange={setDateRange} loading={isLoading}/>
      </Paper>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <DownloadOverlay 
        show={isDownloadingCSV} 
        message="Downloading CSV of all the data, this will take a while. Don't navigate away or click anything."
      />
    </>
  );
});

export default DeviceDetails;
