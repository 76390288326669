import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class EventStore {
  events = [];
  pagination = {};
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadEvents({ page = 1, limit = 100000, sortBy = '', filter = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    this.clearData();
    try {
      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });
      const response = await apiHandler.get(`/events?${queryParams.toString()}`);
      runInAction(() => {
        this.events = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async createEvent(eventData) {
    this.setLoading(true);
    try {
      const newEvent = await apiHandler.post('/events', eventData);
      runInAction(() => {
        this.events.push(newEvent);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateEvent(eventId, updateData) {
    this.setLoading(true);
    try {
      const updatedEvent = await apiHandler.patch(`/events/${eventId}`, updateData);
      runInAction(() => {
        const index = this.events.findIndex(event => event._id === eventId);
        if (index !== -1) {
          this.events[index] = updatedEvent;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteEvent(eventId) {
    this.setLoading(true);
    try {
      await apiHandler.delete(`/events/${eventId}`);
      runInAction(() => {
        this.events = this.events.filter(event => event._id !== eventId);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async downloadEventsCSV({  page = 1, limit = 0, filter = '', sortBy = '' }) {
    this.setLoading(true);
    try {
      const queryParams = new URLSearchParams({ page, limit, filter, sortBy });
      const response = await apiHandler.get(`/events?${queryParams.toString()}`);

      if (response.results && response.results.length) {
        const csvData = this.convertToCSV(response.results);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `events_${new Date().toISOString()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      } else {
        console.error('No data to export.');
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  convertToCSV(objArray) {
    if (objArray.length === 0) {
      return '';
    }

    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    const header = Object.keys(array[0]).join(',');

    let csv = array.map(row => {
      return Object.values(row).map(field => {
        if (typeof field === 'string' && (field.includes(',') || field.includes('\n') || field.includes('\r'))) {
          return `"${field.replace(/(\r\n|\n|\r)/gm, " ")}"`;
        }
        return field;
      }).join(',');
    });

    csv.unshift(header);
    return csv.join('\r\n');
  }



  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('EventStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  clearData() {
    this.events = [];
    this.pagination = {};
  }
}

const eventStore = new EventStore();
export default eventStore;
