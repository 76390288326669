import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent,
  CircularProgress, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle,
  Select, MenuItem, Button, Fab, Snackbar, Alert, CardActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import gatewayStore from '../stores/GatewayStore';
import deviceStore from '../stores/DeviceStore';

const DeviceManager = observer(() => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedGatewayId, setSelectedGatewayId] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  useEffect(() => {
    const fetchData = async () => {
      await gatewayStore.loadGateways();
      await deviceStore.loadDevices();
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCreateGateway = () => navigate('/create-gateway');
  const handleCreateDevice = () => navigate('/create-device');
  const handleEditGateway = (gatewayId) => navigate(`/edit-gateway/${gatewayId}`);
  const handleEditDevice = (deviceId) => navigate(`/edit-device/${deviceId}`);
  const handleDeleteDevice = async (deviceId) => {
    await deviceStore.deleteDevice(deviceId);
    setSnackbar({ open: true, message: 'Device deleted successfully', severity: 'success' });
  };

  const openAssociateDialog = (deviceId) => {
    setSelectedDeviceId(deviceId);
    setDialogOpen(true);
  };

  const handleAssociateDevice = async () => {
    try {
      await gatewayStore.associateDeviceToGateway(selectedGatewayId, selectedDeviceId);
      const deviceUpdateData = { gateway: selectedGatewayId };
      await deviceStore.updateDevice(selectedDeviceId, deviceUpdateData);

      setDialogOpen(false);
      setSnackbar({ open: true, message: 'Device successfully associated with gateway', severity: 'success' });

      await gatewayStore.loadGateways();
      await deviceStore.loadDevices();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error associating device to gateway', severity: 'error' });
    }
  };

  const unassociatedDevices = deviceStore.devices.filter(device => !device.gateway);

  return (
    <Box sx={{ p: 2, m: 2 }}>
      {gatewayStore.isLoading || deviceStore.isLoading ? <CircularProgress /> : (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h4">Gateways</Typography>
            <Button startIcon={<AddIcon />} onClick={handleCreateGateway} variant="outlined">
              Add Gateway
            </Button>
          </Box>
          {gatewayStore.gateways.length === 0 ? (
            <Typography variant="h6" color="textSecondary" align="center">
              No gateways available. Click "Add Gateway" to create one.
            </Typography>
          ) : (
            gatewayStore.gateways.map((gateway) => (
              <Accordion key={gateway._id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography variant="h6">{gateway.name}</Typography>
                    <Tooltip title="Edit Gateway">
                      <IconButton onClick={() => handleEditGateway(gateway._id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {deviceStore.devices.filter(device => device.gateway === gateway._id).map((device) => (
                    <Card key={device._id} sx={{ mb: 2, width: '100%' }}>
                      <CardContent>
                        <Typography variant="h6">{device.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          Serial Number: {device.serialNumber}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Tooltip title="Associate Device">
                          <IconButton onClick={() => openAssociateDialog(device._id)}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Device">
                          <IconButton onClick={() => handleEditDevice(device._id)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Device">
                          <IconButton onClick={() => handleDeleteDevice(device._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          )}

          <Typography variant="h5" sx={{ mt: 4 }}>Unassociated Devices</Typography>
          {unassociatedDevices.length === 0 ? (
            <Typography variant="h6" color="textSecondary" align="center">
              No unassociated devices. Add new devices or associate existing ones to gateways.
            </Typography>
          ) : (
            unassociatedDevices.map((device) => (
              <Card key={device._id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">{device.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Serial Number: {device.serialNumber}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Tooltip title="Associate Device">
                    <IconButton onClick={() => openAssociateDialog(device._id)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Device">
                    <IconButton onClick={() => handleEditDevice(device._id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Device">
                    <IconButton onClick={() => handleDeleteDevice(device._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            ))
          )}

          <Fab color="primary" aria-label="add" onClick={handleCreateDevice} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <AddIcon />
          </Fab>

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Associate Device to Gateway</DialogTitle>
            <DialogContent>
              <Select
                value={selectedGatewayId}
                onChange={(e) => setSelectedGatewayId(e.target.value)}
                fullWidth
              >
                {gatewayStore.gateways.map((gateway) => (
                  <MenuItem key={gateway._id} value={gateway._id}>{gateway.name}</MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleAssociateDevice}>Associate</Button>
            </DialogActions>
          </Dialog>

          <Snackbar 
             open={snackbar.open} 
            autoHideDuration={6000} 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert 
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Box>
  );
});

export default DeviceManager;

                         
