import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class GatewayStore {
  gateways = [];
  isLoading = false;
  error = null;
  pagination = {};

  constructor() {
    makeAutoObservable(this);
  }

  async loadGateways({ page = 1, limit = 10, sortBy = '', filter = '', fields = '', search = '', searchField = 'name' } = {}) {
    this.setLoading(true);
    try {
      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });
      const response = await apiHandler.get(`/gateway?${queryParams.toString()}`);
      runInAction(() => {
        this.gateways = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
  async fetchGatewayById(gatewayId) {
    this.setLoading(true);
    try {
      const response = await apiHandler.get(`/gateway/${gatewayId}`);
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async createGateway(gatewayData) {
    this.setLoading(true);
    try {
      const newGateway = await apiHandler.post('/gateway', gatewayData);
      runInAction(() => {
        this.gateways.push(newGateway);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
  async associateDeviceToGateway(gatewayId, deviceId) {
    this.setLoading(true);
    try {
      
      const updateData = { $addToSet: { devices: deviceId } };
      const updatedGateway = await apiHandler.patch(`/gateway/${gatewayId}`, updateData);
      runInAction(() => {
        
        const index = this.gateways.findIndex(gateway => gateway._id === gatewayId);
        if (index !== -1) {
          this.gateways[index] = updatedGateway;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateGateway(gatewayId, updateData) {
    this.setLoading(true);
    try {
      const updatedGateway = await apiHandler.patch(`/gateway/${gatewayId}`, updateData);
      runInAction(() => {
        const index = this.gateways.findIndex(g => g._id === gatewayId);
        if (index !== -1) {
          this.gateways[index] = updatedGateway;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteGateway(gatewayId) {
    this.setLoading(true);
    try {
      await apiHandler.delete(`/gateway/${gatewayId}`);
      runInAction(() => {
        this.gateways = this.gateways.filter(gateway => gateway._id !== gatewayId);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('GatewayStore Error:', this.error);
  }
}

const gatewayStore = new GatewayStore();
export default gatewayStore;
