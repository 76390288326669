import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class DeviceDetailStore {
  deviceInfo = null;
  deviceLogs = [];
  deviceEvents = [];
  deviceStatus = null;
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadDeviceInfo(deviceId) {
    this.setLoading(true);
    try {
      const info = await apiHandler.get(`/devices/${deviceId}`);
      runInAction(() => {
        this.deviceInfo = info;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceLogs({ deviceId, startDate, endDate, page = 1, limit = 10, sortBy = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    try {
      let filterObj = { device: deviceId };
      if (startDate || endDate) {
        filterObj.timestamp = {};
        if (startDate) filterObj.timestamp.$gte = new Date(startDate).toISOString();
        if (endDate) filterObj.timestamp.$lte = new Date(endDate).toISOString();
      }

      const filter = JSON.stringify(filterObj);
      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });
      const response = await apiHandler.get(`/devices/logs?${queryParams.toString()}`);

      runInAction(() => {
        this.deviceLogs = response.results || [];
        // Set pagination data as needed
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceEvents(deviceId, { page = 1, limit = 10, sortBy = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    try {
      const queryParams = new URLSearchParams({ page, limit, sortBy, fields, search, searchField, device: deviceId });
      const response = await apiHandler.get(`/events?${queryParams.toString()}`);

      runInAction(() => {
        this.deviceEvents = response.results || [];
        // Set pagination data as needed
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceStatus(deviceId) {
    this.setLoading(true);
    try {
      const status = await apiHandler.get(`/devices/${deviceId}/status`);
      runInAction(() => {
        this.deviceStatus = status;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('DeviceDetailStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  clearData() {
    runInAction(() => {
      this.deviceInfo = null;
      this.deviceLogs = [];
      this.deviceEvents = [];
      this.deviceStatus = null;
    });
  }
}

const deviceDetailStore = new DeviceDetailStore();
export default deviceDetailStore;
