import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import gatewayStore from '../stores/GatewayStore';

const GatewayForm = () => {
  const { gatewayId } = useParams();
  const navigate = useNavigate();
  const [gatewayData, setGatewayData] = useState({ name: '', serialNumber: '', ipAddress: '', location: '' });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const isEdit = gatewayId != null;

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      gatewayStore.fetchGatewayById(gatewayId).then(response => {
        setGatewayData({
          name: response.name,
          serialNumber: response.serialNumber,
          ipAddress: response.ipAddress,
          location: response.location
        });
        setLoading(false);
      }).catch(error => {
        console.error("Error fetching gateway:", error);
        setSnackbar({ open: true, message: 'Error fetching gateway data', severity: 'error' });
        setLoading(false);
      });
    }
  }, [gatewayId, isEdit]);

  const handleChange = (e) => {
    setGatewayData({ ...gatewayData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isEdit) {
        await gatewayStore.updateGateway(gatewayId, gatewayData);
        setSnackbar({ open: true, message: 'Gateway updated successfully', severity: 'success' });
      } else {
        await gatewayStore.createGateway(gatewayData);
        setSnackbar({ open: true, message: 'Gateway created successfully', severity: 'success' });
      }
      navigate('/device-manager'); // Redirect to your gateways page
    } catch (error) {
      setSnackbar({ open: true, message: 'Error processing gateway', severity: 'error' });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4">{isEdit ? 'Edit Gateway' : 'Create Gateway'}</Typography>
      <TextField label="Name" name="name" fullWidth margin="normal" value={gatewayData.name} onChange={handleChange} />
      <TextField label="Serial Number" name="serialNumber" fullWidth margin="normal" value={gatewayData.serialNumber} onChange={handleChange} />
      <TextField label="IP Address" name="ipAddress" fullWidth margin="normal" value={gatewayData.ipAddress} onChange={handleChange} />
      <TextField label="Location" name="location" fullWidth margin="normal" value={gatewayData.location} onChange={handleChange} />
      <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }} disabled={loading}>
        {isEdit ? 'Update Gateway' : 'Create Gateway'}
      </Button>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GatewayForm;
