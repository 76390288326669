import { makeAutoObservable, reaction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';
import { timer } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

class AuthStore {
    username = '';
    userId = null;
    email = '';
    password = '';
    token = null;
    isAuthenticated = false;
    userRoles = [];
    errorMessage = '';
    tokenDestructionTimer = null;
    isOTP = false;

    constructor() {
        makeAutoObservable(this);
        this.initializeAuthState();
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('token', token);
                } else {
                    window.localStorage.removeItem('token');
                }
            }
        );
    }

    async initializeAuthState() {
        const storedToken = window.localStorage.getItem('token');
        if (storedToken && this.isTokenValid(storedToken)) {
            this.setToken(storedToken);
            this.userId = window.localStorage.getItem('userId');
            this.scheduleTokenDestruction(storedToken);
        } else {
            this.logout();
        }
    }

    setUsername(username) {
        this.username = username;
    }

    setUserId(userId) {
        this.userId = userId;
        window.localStorage.setItem('userId', userId);
    }

    setEmail(email) {
        this.email = email;
    }

    setPassword(password) {
        this.password = password;
    }

    setErrorMessage(message) {
        this.errorMessage = message;
    }

    setIsAuthenticated(authStatus) {
        this.isAuthenticated = authStatus;
        if (!authStatus) {
            this.logout();
        }
    }

    setToken(tokenValue) {
        this.token = tokenValue;
        window.localStorage.setItem('token', tokenValue);
        if (tokenValue) {
            this.scheduleTokenDestruction(tokenValue);
        } else {
            this.clearTokenDestructionTimer();
        }
    }

    setUserRoles(roles) {
        this.userRoles = roles;
    }

    setIsOTP(isOTP) {
        this.isOTP = isOTP;
    }

    // async fetchUserDetails() {
    //     try {
    //         const response = await apiHandler.get(`/users/${this.userId}`);
    //         if (response) {
    //             this.setUsername(response.username);
    //             this.setEmail(response.email);
    //             this.setUserRoles(response.roles || []);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching user details:', error);
    //         this.logout();
    //     }
    // }

    async login() {
        try {
            const response = await apiHandler.post('auth/signin', {
                username: this.username,
                password: this.password,
            });
            if (response) {
                this.setToken(response.token);
                this.setUserId(response.id);
                this.setUserRoles(response.roles || []);
                this.setUsername(response.username);
                this.setEmail(response.email);
                this.setIsAuthenticated(true);
                this.setIsOTP(response.otp);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            console.error('Error occurred while logging in:', error);
            this.setErrorMessage(error.message || 'Login failed');
            throw error;
        }
    }

    logout() {
        this.setUsername('');
        this.setPassword('');
        this.setEmail('');
        this.setToken(null);
        this.setUserId(null);
        this.userRoles = [];
        this.isAuthenticated = false;
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userId');
        this.clearTokenDestructionTimer();
    }

    destroyToken() {
        this.logout();
    }

    isTokenValid(token) {
        try {
            const decoded = jwtDecode(token);
            return decoded.exp > Date.now() / 1000;
        } catch (error) {
            return false;
        }
    }

    scheduleTokenDestruction(token) {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const delay = (decoded.exp - currentTime) * 1000;

        if (this.tokenDestructionTimer) {
            this.clearTokenDestructionTimer();
        }

        this.tokenDestructionTimer = timer(delay).subscribe(() => {
            this.destroyToken();
        });
    }

    clearTokenDestructionTimer() {
        if (this.tokenDestructionTimer) {
            this.tokenDestructionTimer.unsubscribe();
            this.tokenDestructionTimer = null;
        }
    }

    async signUp() {
        try {
            const response = await apiHandler.post('auth/signup', {
                username: this.username,
                email: this.email,
                password: this.password,
            });
            if (response && response.token) {
                this.setToken(response.token);
                this.setUserId(response.id);
                this.setIsAuthenticated(true);
            }
        } catch (error) {
            console.error('Error occurred while signing up:', error);
            throw error;
        }
    }

    async resetPassword(token, newPassword) {
        try {
            const response = await apiHandler.post('auth/reset-password', {
                resetToken: token,
                newPassword: newPassword,
            });
            return response;
        } catch (error) {
            console.error('Error occurred while resetting password:', error);
            throw error;
        }
    }

    async forgotUsername() {
        try {
            const response = await apiHandler.post('auth/forgot-username', {
                email: this.email,
            });
            return response;
        } catch (error) {
            console.error('Error occurred while requesting username:', error);
            throw error;
        }
    }

    async forgotPassword(email) {
        try {
            const response = await apiHandler.post('auth/forgot-password', {
                email: email,
            });
            return response;
        } catch (error) {
            console.error('Error occurred while requesting password reset:', error);
            throw error;
        }
    }

    async sendSMSVerificationCode(phone, code) {
        try {
            const response = await apiHandler.post('auth/mfa/sms', {
                phoneNumber: phone,
                code: code,
            });
            return response;
        } catch (error) {
            console.error('Error occurred while sending SMS verification code:', error);
            throw error;
        }
    }

    async sendEmailVerificationCode(email, code) {
        try {
            const response = await apiHandler.post('auth/mfa/email', {
                email: email,
                code: code,
            });
            return response;
        } catch (error) {
            console.error('Error occurred while sending email verification code:', error);
            throw error;
        }
    }

    async resendVerificationEmail(email) {
        try {
            const response = await apiHandler.post('/auth/send-verification-email', { email });
            return response;
        } catch (error) {
            console.error('Error occurred while resending verification email:', error);
            throw error;
        }
    }

    async verifyEmail(token) {
        try {
            const response = await apiHandler.post('auth/verify-email', { token });
            return response;
        } catch (error) {
            console.error('Error occurred while verifying email:', error);
            throw error;
        }
    }

    async verifyCode(code) {
        try {
            const response = await apiHandler.post('auth/mfa/verify', {
                code: code,
            });
            return response.isValid;
        } catch (error) {
            console.error('Error occurred while verifying code:', error);
            throw error;
        }
    }
}

const authStore = new AuthStore();
export default authStore;
