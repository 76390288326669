import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import deviceDetailStore from '../stores/DeviceDetailStore';

const DeviceInfo = observer(() => {
  const deviceInfo = deviceDetailStore.deviceInfo;

  return (
    <>
      <Typography variant="h6" gutterBottom>Device Information</Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
        {deviceInfo ? (
          <List>
            <ListItem>
              <ListItemText primary="Device Name" secondary={deviceInfo.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Serial Number" secondary={deviceInfo.serialNumber} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Equipment" secondary={deviceInfo.equipment} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Gateway" secondary={deviceInfo.gateway} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Status" secondary={deviceInfo.status} />
            </ListItem>
            {/* Add more ListItems as needed for other device details */}
          </List>
        ) : (
          <Typography variant="body1" sx={{ my: 2 }}>Loading device information...</Typography>
        )}
      </Box>
    </>
  );
});

export default DeviceInfo;
