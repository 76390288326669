import React, { useRef, useEffect, useState } from 'react';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  useMediaQuery,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Paper
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTheme } from '@mui/material/styles';
import deviceStore from '../stores/DeviceStore';
import { toJS } from 'mobx';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RefreshIcon from '@mui/icons-material/Refresh';
import deviceDetailStore from '../stores/DeviceDetailStore';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const Map = () => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [markers, setMarkers] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [inputValue, setInputValue] = useState('');

  const formatAddress = (address) => {
    if (!address) return 'N/A';
    return `${address.street}, ${address.city}, ${address.state}, ${address.country}`;
  };

  const getMarkerProperties = (device) => {
    let color, IconComponent;
    switch (device.status) {
      case 'connected':
        color = theme.palette.success.main;
        IconComponent = LocationOnIcon;
        break;
      case 'disconnected':
        color = theme.palette.error.main;
        IconComponent = LocationOffIcon;
        break;
      case 'searching':
        color = theme.palette.info.main;
        IconComponent = LocationSearchingIcon;
        break;
      case 'disabled':
        color = theme.palette.grey[500];
        IconComponent = LocationDisabledIcon;
        break;
      case 'editing':
        color = theme.palette.warning.main;
        IconComponent = EditLocationAltIcon;
        break;
      default:
        color = theme.palette.grey[300];
        IconComponent = NotListedLocationIcon;
        break;
    }
    return { color, IconComponent };
  };

  const CustomMarker = ({ color, IconComponent, deviceName, onClick, isSelected, deviceId }) => (
    <div onClick={() => onClick(deviceId)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconComponent 
        sx={{ 
          color: color, 
          stroke: isSelected ? 'white' : 'none', 
          transform: isSelected ? 'scale(1.25)' : 'scale(1)', 
          transition: 'all 0.3s ease' 
        }} 
      />
      <Typography variant="caption">{deviceName}</Typography>
    </div>
  );
  

  const updateMarkers = () => {
    if (!isMapLoaded || !map) return;

    const devices = toJS(deviceStore.devices);
    let bounds = new LngLatBounds();
    let updatedMarkers = {...markers};

    devices.forEach(device => {
      const location = device.locationHistory?.[device.locationHistory.length - 1]?.address?.latLong;
      if (location) {
        const { long, lat } = location;
        const { color, IconComponent } = getMarkerProperties(device);
        const isSelected = device._id === selectedDeviceId;

        if (!updatedMarkers[device._id]) {
          const markerEl = document.createElement('div');
          const root = createRoot(markerEl);
          root.render(
            <CustomMarker 
              color={color}
              IconComponent={IconComponent}
              deviceName={device.deviceName}
              onClick={() => handleDeviceSelection(device._id)}
              isSelected={isSelected}
              deviceId={device._id}
            />
          );

          const marker = new mapboxgl.Marker(markerEl)
            .setLngLat([long, lat])
            .addTo(map);

          updatedMarkers[device._id] = marker;
        } else {
          const markerEl = updatedMarkers[device._id].getElement();
          const root = createRoot(markerEl);
          root.render(
            <CustomMarker 
              color={color}
              IconComponent={IconComponent}
              deviceName={device.deviceName}
              onClick={() => handleDeviceSelection(device._id)}
              isSelected={isSelected}
              deviceId={device._id}
            />
          );

          updatedMarkers[device._id].setLngLat([long, lat]); // Update marker position
        }

        bounds.extend([long, lat]);
      }
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, { padding: 40, maxZoom: 15 });
    }

    setMarkers(updatedMarkers);
  };
  
  const handleDeviceSelection = (deviceId) => {
    setSelectedDeviceId(deviceId);
    const selectedDevice = deviceStore.devices.find(device => device._id === deviceId);
    setSelectedDeviceDetails(selectedDevice);
    setInputValue(selectedDevice?.deviceName || '');
    setDrawerOpen(true);

    deviceDetailStore.loadDeviceInfo(deviceId);

    Object.keys(markers).forEach(key => {
      const isSelected = key === deviceId;
      const markerEl = markers[key].getElement();
      const device = deviceStore.devices.find(d => d._id === key);
      const { color, IconComponent } = getMarkerProperties(device);
      const root = createRoot(markerEl);
      root.render(
        <CustomMarker 
          color={color}
          IconComponent={IconComponent}
          deviceName={device.deviceName}
          onClick={handleDeviceSelection}
          isSelected={isSelected}
          deviceId={device._id}
        />
      );
    });
  
    const location = selectedDevice?.locationHistory?.[selectedDevice.locationHistory.length - 1]?.address?.latLong;
    if (location) {
      map.flyTo({ center: [location.long, location.lat], zoom: 15 });
    } else {
      console.error('Location data not available for the selected device.');
    }
  };

  const handleResize = () => {
    if (map) {
      map.resize();
    }
  };

  useEffect(() => {
    if (isMapLoaded) {
      addCustomControls();
    }
  }, [isMapLoaded]);

  useEffect(() => {
    const handleResize = () => {
      if (map) {
        map.resize();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [map]);

  useEffect(() => {
    const initializeMap = () => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/dark-v11',
        center: [-98.5795, 39.8283],
        zoom: 4
      });

      map.on('load', () => {
        setMap(map);
        map.resize();
        setIsMapLoaded(true);
        updateMarkers();
      });

      return () => map.remove();
    };

    if (!map) initializeMap();
  }, [map]);

  useEffect(updateMarkers, [deviceStore.devices, isMapLoaded, map]);

  useEffect(() => {
    if (selectedDeviceId) {
      deviceDetailStore.loadDeviceInfo(selectedDeviceId);
    }
  }, [selectedDeviceId]);


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [map]);

  const addCustomControls = () => {
    if (true) return;
    const controlButtons = [
      { icon: <ZoomInIcon />, title: 'Zoom In', action: () => map.zoomIn() },
      { icon: <ZoomOutIcon />, title: 'Zoom Out', action: () => map.zoomOut() },
      { icon: <RefreshIcon />, title: 'Reset Zoom', action: () => map.fitBounds(map.getBounds()) },
      { icon: <MyLocationIcon />, title: 'My Location', action: () => { /* logic for My Location */ } },
      { icon: <LocationOnIcon />, title: 'Active Devices', action: () => { /* logic for Active Devices */ } },
      { icon: <LocationOffIcon />, title: 'All Devices', action: () => { /* logic for All Devices */ } },
    ];

    controlButtons.forEach(btn => {
      const ctrlBtn = document.createElement('div');
      ctrlBtn.style.zIndex = "10"; // Setting a higher z-index
      ctrlBtn.style.position = "absolute"; // Ensuring the position is set to absolute
      // Additional styles can be added here as needed
  
      const root = createRoot(ctrlBtn);
      root.render(
        <Paper elevation={3} style={{ padding: '6px', cursor: 'pointer', margin: '5px' }} onClick={btn.action}>
          {btn.icon}
        </Paper>
      );
      map.getContainer().appendChild(ctrlBtn);
    });
  };

  useEffect(() => {
    if (isMapLoaded) {
      addCustomControls();
    }
  }, [isMapLoaded]);

  return (
    <Box container sx={{ width: '100%', height: 'calc(100vh - 192px)', overflow: 'hidden' }}>
      <Box
        ref={mapContainerRef}
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <Autocomplete
          id="device-autocomplete"
          options={deviceStore.devices}
          getOptionLabel={(option) => option.deviceName}
          style={{ width: 300, position: 'absolute', top: 10, left: 10, zIndex: 1000 }}
          renderInput={(params) => <TextField {...params} label="Select Device" />}
          value={deviceStore.devices.find(device => device._id === selectedDeviceId)}
          onChange={(event, newValue) => {
            handleDeviceSelection(newValue?._id);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000
          }}
        >
          <IconButton onClick={() => map.zoomIn()} sx={{ mb: 1 }}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={() => map.zoomOut()} sx={{ mb: 1 }}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton onClick={() => map.fitBounds(map.getBounds())}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <Drawer
          variant="persistent"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          anchor="right"
          sx={{
            width: drawerOpen ? 250 : 0,
            '& .MuiDrawer-paper': {
              width: 250,
              height: 'calc(100% - 64px)',
              marginTop: '64px',
              boxSizing: 'border-box',
              overflowX: 'hidden',
            },
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)} sx={{ width: '36px', height: '36px' }}>
          {drawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
        </IconButton>
        {selectedDeviceDetails ? (
          <List>
            <ListItem>
              <ListItemText primary="Device Name" secondary={selectedDeviceDetails.deviceName} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Device Status" secondary={selectedDeviceDetails.status} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Location" secondary={formatAddress(selectedDeviceDetails.locationHistory?.[selectedDeviceDetails.locationHistory.length - 1]?.address)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Coordinates" secondary={`${selectedDeviceDetails.locationHistory?.[selectedDeviceDetails.locationHistory.length - 1]?.address?.latLong.lat}, ${selectedDeviceDetails.locationHistory?.[selectedDeviceDetails.locationHistory.length - 1]?.address?.latLong.long}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Last Movement"
                secondary={
                  selectedDeviceDetails.locationHistory?.[selectedDeviceDetails.locationHistory.length - 1]?.timestamp
                    ? new Date(selectedDeviceDetails.locationHistory?.[selectedDeviceDetails.locationHistory.length - 1]?.timestamp).toLocaleString()
                    : 'N/A'
                }
              />
            </ListItem>
            <Divider />
          </List>
        ) : (
          <Typography variant="body1" sx={{ m: 2 }}>Select a device to view details</Typography>
        )}
      </Drawer>
      {!drawerOpen && (
        <IconButton
          onClick={() => setDrawerOpen(!drawerOpen)}
          sx={{
            position: 'absolute',
            top: 72,
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
       )}
     </Box>
  );
};

export default Map;