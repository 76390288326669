import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import authStore from '../stores/AuthStore';
import DefaultRoutes from './DefaultRoutes';

const ProtectedRoute = observer(({ children, requiredRole, defaultRoute }) => {
  const isAuthenticated = authStore.isAuthenticated;
  const token = authStore.token;
  const userRoles = toJS(authStore.userRoles);

  if (!isAuthenticated || !token) {
    return <Navigate to="/login" replace />;
  }

  const hasRequiredRole = isAuthenticated && token && userRoles.some(role => role === requiredRole || role === 'SUPERADMIN');

  const resolveFallbackRoute = () => {
    if (defaultRoute) return defaultRoute;
    for (const role of userRoles) {
      if (DefaultRoutes[role]) {
        return DefaultRoutes[role];
      }
    }
    return '/';
  };

  return hasRequiredRole ? children : <Navigate to={resolveFallbackRoute()} replace />;
});

export default ProtectedRoute;
