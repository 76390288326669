import React, { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Snackbar, Alert, Paper } from "@mui/material";
import authStore from "../stores/AuthStore";
import { styled, keyframes } from "@mui/material/styles";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from "../assets/logo.png";
import { Link } from "@mui/material";

const fadeInBlur = keyframes`
  0% {
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
`;

const Logo = styled(LazyLoadImage)({
  display: 'block',
  width: 'auto',
  height: '150px',
  margin: '1rem auto',
  animation: `${fadeInBlur} 1s ease-in`,
});

const StyledPaper = styled(Paper)({
  padding: "3rem",
  maxWidth: "500px",
  margin: "2rem auto",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
});

const LoginPage = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
        await authStore.login();

        // check if OTP used and send reset password email if so
        if (authStore.isOTP) {
          await authStore.forgotPassword(authStore.email);
          setSeverity("success");
          setSnackbarMessage("Reset password email sent");
        }
        
        // Check for roles in the authStore after successful login
        if (authStore.userRoles.length > 0) {
            const isItAdmin = authStore.userRoles.includes('ITADMIN');
            setSeverity("success");
            setSnackbarMessage("Login successful");

            // Redirect based on the role
            navigate(isItAdmin ? "/users" : "/");
        } else {
            // Handle scenario where the roles are not set
            setSeverity("error");
            setSnackbarMessage("Roles not set");
        }
    } catch (error) {
        // Check if the error is due to email not being verified
        if (error.response?.data && /Email not verified/.test(error.response.data.message)) {
            navigate("/verify-email");
        } else {
            setSeverity("error");
            setSnackbarMessage(error.message || "Login failed");
        }
    } finally {
        setOpenSnackbar(true);
    }
};


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background:
          "radial-gradient(circle, hsla(0, 0%, 10%, 1) 0%, hsla(0, 0%, 2%, 1) 64%, hsla(0, 0%, 2%, 1) 100%)",
      }}
    >
      <Logo src={logo} alt="Kratesoft Logo" />
      <StyledPaper
        elevation={10}
        style={{ width: "600px", maxWidth: "80%", height: "400px" }}
      >
        <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
          Login
        </Typography>
        <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            label="Username"
            variant="outlined"
            value={authStore.username}
            onChange={(e) => authStore.setUsername(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={authStore.password}
            onChange={(e) => authStore.setPassword(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
          />
          <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Typography variant="body2">
              <Link href="/forgot-password" color="inherit">
                Forgot password?
              </Link>
            </Typography>
          </Box>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StyledPaper>
    </Box>
  );
});

export default LoginPage;


