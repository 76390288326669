import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';
import deviceStore from '../stores/DeviceStore';

const DeviceForm = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState({ name: '', serialNumber: '', equipmentId: '', status: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const isEdit = deviceId != null;

  useEffect(() => {
    if (isEdit) {
      const fetchDevice = async () => {
        const data = await deviceStore.fetchDeviceById(deviceId);
        setDeviceData(data);
      };
      fetchDevice();
    }
  }, [deviceId, isEdit]);

  const handleChange = (e) => {
    setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (isEdit) {
        await deviceStore.updateDevice(deviceId, deviceData);
        setSnackbar({ open: true, message: 'Device updated successfully', severity: 'success' });
      } else {
        await deviceStore.createDevice(deviceData);
        setSnackbar({ open: true, message: 'Device created successfully', severity: 'success' });
      }
      navigate('/devices'); // Redirect to device list
    } catch (error) {
      setSnackbar({ open: true, message: 'Error processing device', severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4">{isEdit ? 'Edit Device' : 'Create Device'}</Typography>
      <TextField label="Name" name="name" fullWidth margin="normal" value={deviceData.name} onChange={handleChange} />
      <TextField label="Serial Number" name="serialNumber" fullWidth margin="normal" value={deviceData.serialNumber} onChange={handleChange} />
      <TextField label="Equipment ID" name="equipmentId" fullWidth margin="normal" value={deviceData.equipmentId} onChange={handleChange} />
      <TextField label="Status" name="status" fullWidth margin="normal" value={deviceData.status} onChange={handleChange} />
      <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
        {isEdit ? 'Update Device' : 'Create Device'}
      </Button>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeviceForm;
