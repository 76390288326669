import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import UsersStore from '../stores/UsersStore';
import authStore from '../stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, List, ListItem, ListItemText, 
  ListItemAvatar, Avatar, Divider, Paper, IconButton, Button, 
  Snackbar, Alert, ListItemSecondaryAction, Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

const Users = observer(() => {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    UsersStore.fetchUsers();
  }, []);

  const handleEditUser = (userId) => {
    navigate(`/user/${userId}`);
  };

  const handleApproval = async (userId) => {
    try {
      await UsersStore.approveUser(userId, true);
      setSnackbar({ open: true, message: 'User approved', severity: 'success' });
    } catch (error) {
      console.error('Error approving user:', error);
      setSnackbar({ open: true, message: 'Error approving user', severity: 'error' });
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await UsersStore.deleteUser(userId);
      setSnackbar({ open: true, message: 'User deleted', severity: 'success' });
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({ open: true, message: 'Error deleting user', severity: 'error' });
    }
  };

  const canDeleteUsers = authStore.userRoles.includes('ITADMIN') || authStore.userRoles.includes('SUPERADMIN');

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
        <Button variant="outlined" color="primary" onClick={() => navigate('/user/new')}>
          <Typography variant="body1" sx={{ mr: 1 }}>Add User</Typography>
          <AddIcon />
        </Button>
      </Box>

      <Paper elevation={2} sx={{ mb: 2, mx: 2 }}>
        <Typography variant="h6" sx={{ p: 2 }}>Pending Users</Typography>
        <Divider />
        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
          {UsersStore.users.filter(user => user.isPending).map((user) => (
            <ListItem key={user._id} button onClick={() => handleEditUser(user._id)}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} secondary={user.email} />
                <ListItemSecondaryAction>
                  <Tooltip title="Approve User">
                    <IconButton edge="end" onClick={() => handleApproval(user._id)} sx={{mr:1}}>
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton edge="end" onClick={() => handleDeleteUser(user._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper elevation={2} sx={{ mx: 2 }}>
        <Typography variant="h6" sx={{ p: 2 }}>Approved Users</Typography>
        <Divider />
        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
          {UsersStore.users.filter(user => !user.isPending).map((user) => (
            <ListItem button key={user._id} onClick={() => handleEditUser(user._id)}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} secondary={user.email} />
              {canDeleteUsers && (
                <ListItemSecondaryAction>
                  <Tooltip title="Delete User">
                    <IconButton edge="end" onClick={() => handleDeleteUser(user._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
});

export default Users;
