import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class DeviceStore {
  devices = [];
  deviceLogs = [];
  isLoading = false;
  error = null;
  selectedDevice = null;
  pagination = {};

  constructor() {
    makeAutoObservable(this);
  }

  async loadDevices({ page = 1, limit = 10, sortBy = '', filter = '', fields = '', search = '', searchField = 'name' } = {}) {
    this.setLoading(true);
    try {
      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });
      const response = await apiHandler.get(`/devices?${queryParams.toString()}`);
      runInAction(() => {
        this.devices = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
  async fetchDeviceById(deviceId) {
    this.setLoading(true);
    try {
      const response = await apiHandler.get(`/devices/${deviceId}`);
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async createDevice(deviceData) {
    this.setLoading(true);
    try {
      if (!deviceData.status) {
        deviceData.status = "active";
      }
  
      const newDevice = await apiHandler.post('/devices', deviceData);
      runInAction(() => {
        this.devices.push(newDevice);
      });
    } catch (error) {
      console.error("Error creating device:", error.response || error.message);
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async associateDeviceToGateway(deviceId, gatewayId) {
    this.setLoading(true);
    try {
      const updateData = { gateway: gatewayId };
      const updatedDevice = await apiHandler.patch(`/devices/${deviceId}`, updateData);
      runInAction(() => {
        
        const index = this.devices.findIndex(device => device._id === deviceId);
        if (index !== -1) {
          this.devices[index] = updatedDevice;
        }
        if (this.selectedDevice && this.selectedDevice._id === deviceId) {
          this.selectedDevice = updatedDevice;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }


  async updateDevice(deviceId, deviceData) {
    this.setLoading(true);
    try {
      const updatedDevice = await apiHandler.patch(`/devices/${deviceId}`, deviceData);
      runInAction(() => {
        const index = this.devices.findIndex(device => device._id === deviceId);
        if (index !== -1) {
          this.devices[index] = updatedDevice;
        }
        if (this.selectedDevice && this.selectedDevice._id === deviceId) {
          this.selectedDevice = updatedDevice;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteDevice(deviceId) {
    this.setLoading(true);
    try {
      await apiHandler.delete(`/devices/${deviceId}`);
      runInAction(() => {
        this.devices = this.devices.filter(device => device._id !== deviceId);
        if (this.selectedDevice && this.selectedDevice._id === deviceId) {
          this.selectedDevice = null;
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceById(deviceId) {
    this.setLoading(true);
    try {
      const device = await apiHandler.get(`/devices/${deviceId}`);
      runInAction(() => {
        this.selectedDevice = device;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('DeviceStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  clearSelectedDevice() {
    this.selectedDevice = null;
  }
}

const deviceStore = new DeviceStore();
export default deviceStore;
