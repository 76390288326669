import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, useMediaQuery } from '@mui/material';

import Dashboard from './pages/Dashboard';
import DeviceDetails from './pages/DeviceDetails';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Users from './pages/Users';
import ResetPassword from './pages/ResetPassword';
import SettingsPage from './pages/Settings';
import SignUp from './pages/SignUp';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import DeviceManager from './components/DeviceManager';
import VerifyEmail from './pages/VerifyEmail';
import Help from './pages/Help';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import UserForm from './pages/UserForm';
import AlertsPage from './pages/AlertsPage';
import DeviceForm from './components/DeviceForm';
import GatewayForm from './components/GatewayForm';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#444444',
      paper: '#212121',
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#AAAAAA',
    },
  },
  typography: {
    fontFamily: '"IBM Plex Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    // ... other typography variants
  },
});
const App = () => {
  const Layout = () => {
    const location = useLocation();
    const modalPages = ['/login', '/signup', '/forgot-password', '/reset-password', '/verify-email'];
    const isModal = modalPages.includes(location.pathname);
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column',
      }}>
        {!isModal && <Navbar/>}
        <Box sx={{ flexGrow: 1, marginLeft: isMobile ? 0 : isModal? 0 : '250px', transition: 'margin-left 0.3s ease', mt: isModal? 0 : 8}}>
          <Routes>
            <Route path="/" element={<ProtectedRoute requiredRole={'USER'}><Dashboard /></ProtectedRoute>} />
            <Route path="/device/:id" element={<ProtectedRoute requiredRole={'USER'}><DeviceDetails /></ProtectedRoute>} />
            <Route path="/device-status" element={<ProtectedRoute requiredRole={'USER'}><DeviceManager /></ProtectedRoute>} />
            <Route path="/device-status" element={<ProtectedRoute requiredRole={'USER'}><DeviceManager /></ProtectedRoute>} />
            <Route path="/create-device" element={<ProtectedRoute requiredRole={'USER'}><DeviceForm /></ProtectedRoute>} />
            <Route path="/create-gateway" element={<ProtectedRoute requiredRole={'USER'}><GatewayForm /></ProtectedRoute>} />
            <Route path="/edit-device/:deviceId" element={<ProtectedRoute requiredRole={'USER'}><DeviceForm /></ProtectedRoute>} />
            <Route path="/edit-gateway/:gatewayId" element={<ProtectedRoute requiredRole={'USER'}><GatewayForm /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute requiredRole={'USER'}><Dashboard /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute requiredRole={'USER'}><SettingsPage /></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute requiredRole={'ITADMIN'}><Users /></ProtectedRoute>} />
            <Route path="/user/:id" element={<ProtectedRoute requiredRole={'ITADMIN'}><UserForm /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/help" element={<Help />} />
            <Route path="/alerts/:id" element={<ProtectedRoute requiredRole={'USER'}><AlertsPage /></ProtectedRoute>} />
            <Route path="/alerts" element={<ProtectedRoute requiredRole={'USER'}><AlertsPage /></ProtectedRoute>} />
            <Route path="*" element={<ProtectedRoute requiredRole={'USER'}><Dashboard /></ProtectedRoute>} />
            {/* Add any additional routes here */}
          </Routes>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Layout />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
